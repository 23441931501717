@import "~@src/styles/flex.less";

.footer {
  background-image: url("../../images/footer/footerbg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  min-width: 1200px;

  .footerBlock {
    .df();
    .frw();
    .jcc();
    width: 1200px;
    height: 372px;
    margin: 0 auto;
    font-size: 14px;
    color: #979797;
    letter-spacing: 0;
    text-align: justify;
  }

  .footerText {
    height: 180px;
    margin-top: 120px;
    text-align: center;
  }

  .footerRecord {
    height: 25px;
    line-height: 25px;
  }

  .shanxiIcon{
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .footerTel {
    text-decoration: none;
    color: #979797;
  }

  .footerLink {
    cursor: pointer;
    text-decoration: none;
    color: #979797;
  }

  .footerImgLink{
    cursor: pointer;
    text-decoration: none;
  }

  .footerCentre {
    margin-top: 32px;
    height: 20px;
    letter-spacing: 0.15px;
  }

  .footerIcon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .footerChat {
    margin-top: 30px;
    height: 24px;
    line-height: 24px;
  }

  .footerPhone {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url("../../images/footer/phone.png");
    margin-right: 4px;
  }

  .footerLine {
    width: 1200px;
    height: 1px;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(214, 226, 250, 0.1) 10%,
      rgba(214, 226, 250, 0.33) 20%,
      rgba(214, 226, 250, 0.44) 30%,
      rgba(214, 226, 250, 0.55) 40%,
      rgba(214, 226, 250, 0.88) 50%,
      rgba(214, 226, 250, 0.55) 60%,
      rgba(214, 226, 250, 0.44) 70%,
      rgba(214, 226, 250, 0.33) 80%,
      rgba(214, 226, 250, 0.1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .footerDown {
    cursor: pointer;
    display: inline-block;
  }
}
