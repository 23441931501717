.only-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

/*
 * 工具类样式，包括margin, padding, 文字，段落, border，宽度
*/

//公共的边距
//用法：类型-方向-大小
//类型(margin,padding,pull-margin), 方向(top,right,bottom,left,top-bottom,left-right，不填为4个方向), 大小(s:8px; m:16px; l:20px; none:0);
//@gap-s-base       : 8px;
//@gap-m-base       : 16px;
//@gap-l-base       : 20px;
//如：1. margin-s,输出  margin:8px;
//    2. margin-top-s,输出  margin-top:8px;
//    3. pull-margin-top-s,输出  margin-top:-8px;
/*
 * 工具类样式，包括margin, padding, 文字，段落, border
*/
.margin-mixin(@gap,@level){

  &-top-@{level} {
      margin-top : @gap;
  }
  &-right-@{level} {
      margin-right : @gap;
  }
  &-bottom-@{level} {
      margin-bottom : @gap;
  }
  &-left-@{level} {
      margin-left : @gap;
  }
  &-top-bottom-@{level} {
      margin-top : @gap;
      margin-bottom : @gap;
  }
  &-left-right-@{level} {
      margin-left : @gap;
      margin-right : @gap;
  }
  &-@{level} {
      margin : @gap;
  }
}

.margin{
  .margin-mixin(@gap-s-base,s);
  .margin-mixin(@gap-m-base,m);
  .margin-mixin(@gap-l-base,l);
  .margin-mixin(0,none);
}

.pull-margin{
  .margin-mixin(-@gap-s-base,s);
  .margin-mixin(-@gap-m-base,m);
  .margin-mixin(-@gap-l-base,l);
}

.padding-mixin(@gap,@level){

  &-top-@{level} {
      padding-top : @gap;
  }
  &-right-@{level} {
      padding-right : @gap;
  }
  &-bottom-@{level} {
      padding-bottom : @gap;
  }
  &-left-@{level} {
      padding-left : @gap;
  }
  &-top-bottom-@{level} {
      padding-top : @gap;
      padding-bottom : @gap;
  }
  &-left-right-@{level} {
      padding-left : @gap;
      padding-right : @gap;
  }
  &-@{level} {
      padding : @gap;
  }
}

.padding{
  .padding-mixin(@gap-s-base,s);
  .padding-mixin(@gap-m-base,m);
  .padding-mixin(@gap-l-base,l);
  .padding-mixin(0,none);
}

/*
* ZN gap system
* Generate class: pd0, pd5, pdl5, mg5, mgr15, ...
* Naming Rule: pdl --> padding-left, pdr --> padding-right
* Support value: 0, 5, 8, 10, 15, 20
*/

@gapSystemListValue:0, 5, 8, 10, 12, 15, 16, 18, 20,24,32,44;
@gapSystemListGap:0px, 5px, 8px, 10px, 12px, 15px, 16px, 18px, 20px,24px,32px,44px;

.pd(@value, @gap){
  .pd@{value} {
    padding: @gap;
  }
  .pd@{value}i {
    padding: @gap!important;
  }
  .pdl@{value} {
    padding-left: @gap;
  }
  .pdl@{value}i {
    padding-left: @gap!important;
  }
  .pdr@{value} {
    padding-right: @gap;
  }
  .pdr@{value}i {
    padding-right: @gap!important;
  }
  .pd@{value} {
    padding-top: @gap;
  }
  .pdt@{value}i {
    padding-top: @gap!important;
  }
  .pdb@{value} {
    padding-bottom: @gap;
  }
  .pdb@{value}i {
    padding-bottom: @gap!important;
  }
  /* horizantal */
  .pdh@{value} {
    padding:0 @gap;
  }
  .pdh@{value}i {
    padding:0 @gap!important;
  }
  /* vertical */
  .pdv@{value} {
    padding: @gap 0;
  }
  .pdv@{value}i {
    padding:@gap 0!important;
  }
}

.mg(@value, @gap){
  .mg@{value} {
    margin: @gap;
  }
  .mg@{value}i {
    margin: @gap!important;
  }
  .mgl@{value} {
    margin-left: @gap;
  }
  .mgl@{value}i {
    margin-left: @gap!important;
  }
  .mgr@{value} {
    margin-right: @gap;
  }
  .mgr@{value}i {
    margin-right: @gap!important;
  }
  .mgt@{value} {
    margin-top: @gap;
  }
  .mgt@{value}i {
    margin-top: @gap!important;
  }
  .mgb@{value} {
    margin-bottom: @gap;
  }
  .mgb@{value}i {
    margin-bottom: @gap!important;
  }
  .mgh@{value} {
    margin: 0 @gap;
  }
  .mgh@{value}i {
    margin: 0 @gap!important;
  }
  .mgv@{value} {
    margin: @gap 0;
  }
  .mgv@{value}i {
    margin: @gap 0!important;
  }
}

.loopPosition(@value, @gap) {
  .pd(@value, @gap);
  .mg(@value, @gap);
}

.loopGap(@i) when (@i < length(@gapSystemListValue)+1) {
  .loopPosition(extract(@gapSystemListValue, @i), extract(@gapSystemListGap, @i));
  .loopGap(@i+1);
}

.loopGap(1);

// Gap System End

// 文字显示多少行后省略号
.lineClamp(@value) {
  .line-clamp-@{value} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: @value;
    -webkit-box-orient: vertical;
  }
}
.lineClamp(2);

.hidden{
  display: none;
}

.show{
  display: block;
}

.inline{
  display: inline-block;
}

.fl{
  float : left;
}

.fr{
  float : right;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.vertical-middle{
  vertical-align: middle;
}

.main-container{
  padding-right : 10px;
  padding-top : 5px;
  height: 100%;
}
#react-content{
  height: 100%;
}

.font-s, .font-size-s{
  font-size : 10px;
}
.font-n, .font-size-normal, .font12{
  font-size : 12px;
}

.font-m, .font-size-m, .font14{
  font-size : 14px;
}

.font-l, .font-size-l, .font16{
  font-size : 16px;
}

.pointer{
  cursor : pointer;
}

.bold{
  font-weight: bolder;
}

.underline{
  text-decoration: underline;
}

.underline:hover{
  text-decoration: underline;
}

.line-height-double{
  line-height: 2.5;
}

/**********************工具类********************************/
.height100percents{
  height:100%;
  min-height:100%;
}

.clear-both{
  clear: both;
}


// 页面圆角
.page-radius {
  border-radius: 4px;
}

//边框
.border-base{
  border: 1px solid @base-border-color;
  border-radius: @border-radius-base;
}

.no-radius{
  border-radius : 0;
}

.dashed {
  border-style: dashed
}

.multi-no-wrap(@num) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  }
