.df, .fcn, .frn, .fcw, .frw {
	display: flex;
}

.fcn {
	flex-direction: column;
	flex-wrap: nowrap;
}

.frn {
	flex-direction: row;
	flex-wrap: nowrap;
}

.fcw {
	flex-direction: column;
	flex-wrap: wrap;
}

.frw {
	flex-direction: row;
	flex-wrap: wrap;
}

.fm {
	flex: 1;
}

.fn {
	flex: none;
}

.aic {
	align-items: center;
}

.ais {
	align-items: flex-start;
}
.asc {
	align-self: center;
}

.aie {
	align-items: flex-end;
}

.acc {
	align-content: center;
}

.acs {
	align-content: flex-start;
}

.ace {
	align-content: flex-end;
}

.acr {
	align-content: space-around
}

.acb {
	align-content: space-between
}


.jcc {
	justify-content: center;
}

.jcs {
	justify-content: flex-start;
}

.jce {
	justify-content: flex-end;
}

.jcr {
	justify-content: space-around;
}

.jcb {
	justify-content: space-between;
}