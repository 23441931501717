@import './vars.less';
@themeColor: @tc-primary;
@px: (100vw)/1920;

.mainColorBg() {
  background-color: @primary-color;
  color: white;
}

// 背景图片
.bg-image (@url) {
  // background-image: url('~/src/images/@{url}');
  // background-image: url('~/src/images/login/login-bg.png');
  // background-image: url('../images/login/login-bg.png');
  background-image: url('../images/@{url}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


// .bg-image (@url) {
//   background-image: url('../images/@{url}@2x.png');
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 100% 100%;
//   @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
//     background-image: url('../images/@{url}@3x.png');
//   }
// }

// .bg-image-jpg (@url) {
//   background-image: url('../images/@{url}@2x.jpg');
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 100% 100%;
//   @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
//     background-image: url('../images/@{url}@3x.jpg');
//   }
// }

// 不换行 ...展示
.no-wrap() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

//设置多行 ...展示
.multi-no-wrap(@num) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

//上下左右居中
.position-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//flex上下左右居中
.position-flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

//上下居中
.position-y-center() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

//左右居中
.position-x-center() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

//定义宽高
.width-height(@width, @height) {
  width: @width;
  height: @height;
}

.no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  
  -khtml-user-select: none; /* Konqueror */
  
  -moz-user-select: none; /* Firefox */
  
  -ms-user-select: none; /* Internet Explorer/Edge */
  
  user-select: none;
}

.comContent {
	height: 100vh;
	padding-top: 116@px;
}