@import '~@src/styles/mixin.less';

.page-container {
  background: #FFF9F9;
  min-width: 1200px;
}
.page-list-box {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}

.page-item {
  margin-bottom: 20px;
}
.page-item-image {
  width: 100%;
  height: 160px;
  position: relative;
}
.page-item-image-play {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 60px;
  left: 45%;
}
.page-item-text-box {
  width: 100%;
  height: 148px;
  background: #F4F4F4;
  border-radius: 0 0 2px 2px;
  box-sizing: border-box;
  padding: 13px 14px;
}

.page-item-title {
  .multi-no-wrap(2);
  height: 48px;
  font-weight: bold;
  font-size: 18px;
  color: #272727;
  letter-spacing: 0;
  text-align: justify;
  line-height: 24px;
  font-weight: bold;
}
.page-item-intro {
  .multi-no-wrap(2);
  margin-top: 8px;
  height: 38px;
  font-size: 14px;
  color: #939393;
  letter-spacing: 0;
  text-align: justify;
  line-height: 19px;
}

.page-item-bottom {
  margin-top: 16px;
  font-size: 12px;
  color: #C1C1C1;
  text-align: right;
  line-height: 12px;
}
.page-item-time {
  display: inline-flex;
  align-items: center;
}
.page-item-time-img {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
