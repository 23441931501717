@import './tool.less';
@import './vars.less';
@import './quill.less';
@import './common.less';

.g-app {
  padding: 0 10px 10px 20px;
}
.primary-color {
  color:  @tc-primary !important;
}
.span-btn {
  &:extend(.normal-btn);
  color: @tc-primary;
}
.normal-btn {
  cursor: pointer;
  &:hover {
    color: @tc-light;
  }
}
.span-round {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #bfbfbf;
}
.width70px {
  width: 70px;
}
.width80px {
  width: 80px;
}
.width100px {
  width: 100px;
}
.width120px {
  width: 120px;
}
.width200px {
  width: 200px;
}

.wp10 {
  width: 10%;
}
.wp15 {
  width: 15%;
}
.wp20 {
  width: 20%;
}
.wp25 {
  width: 25%;
}
.wp30 {
  width: 30%;
}
.wp35 {
  width: 35%;
}
.wp40 {
  width: 40%;
}
.wp45 {
  width: 45%;
}
.wp48 {
  width: 48%;
}
.wp49 {
  width: 49%;
}
.wp50 {
  width: 50%;
}
.wp55 {
  width: 55%;
}
.wp60 {
  width: 60%;
}
.wp65 {
  width: 65%;
}
.wp70 {
  width: 70%;
}
.wp75 {
  width: 75%;
}
.wp80 {
  width: 80%;
}
.wp85 {
  width: 85%;
}
.wp90 {
  width: 90%;
}
.wp95 {
  width: 95%;
}
.wp100 {
  width: 100%;
}
.cursorPointer {
  cursor: pointer;
}
.comLayout {
  padding: 2px 24px 10px 24px;
}

.common-card {
  width: 1200px;
  margin: 20px auto;
}
.banner-img-140 {
  width: 100%;
  height: 140px;
  object-fit: cover;
  cursor: pointer;
}

.page-gray {
  // filter: grayscale(95%);
  // -moz-filter: grayscale(95%); /* 其他内核现在并不支持，为了将来兼容性书写 */
  // -ms-filter: grayscale(95%);
  // -o-filter: grayscale(95%);
  // filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");  /* Firefox 3.5+ */
  // filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=.95);
  // -webkit-filter: grayscale(.95);
}
